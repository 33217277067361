<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<script>
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import yzSelect from "@/components/form/yzSelect";
  import {getDicts} from "@/api/common/dict";
  import {addWorkOrderApply} from "@/api/admin/workOrder/apply";



  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
      yzSelect,
    },
    data() {
      return {
        title: "工单提交",
        items: [{
            text: "用户首页",
            href:"/admin"
          },
          {
            text: "工单提交",
            active: true
          }
        ],
        dict: {},
        form: {
          sid: ""
        },
        files: [],
      };
    },
    methods:{
      closeFile(f){
        this.files = this.files.filter(item=>{
          return item != f
        })
      },
      uploadRes(e){
        this.files.push(
            {
              fileSort : new Date().getTime().toString().slice(0, 8),
              fileName : e.name,
              fileUrl : e.fileId,
              fileType : e.ext,
              fileSize : e.size,
            }
        )
        this.$forceUpdate()
      },
      commit(){
        if (!this.form.applyTitle || !this.form.applyContent){
          this.$notify({
            type: "warning",
            message: "请填写必填项"
          })
          return
        }
        this.$confirm("提交后将无法修改请确认无误后提交", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.form.files = JSON.stringify(this.files)
          addWorkOrderApply(this.form).then(res=>{
            if (res.status) {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
              window.location.href = "/workorderList"
            }
          })
        });
      },
      show() {
        this.form = {
          sid: ""
        }
        this.showModal = true
      }
      ,
      hide() {
        this.showModal = false
      }
    },
    mounted() {
      getDicts("gddl", "gdxl", "gdzycd").then(res=> {
        this.dict = res
      })
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div>
              <div class="workorder-box">
                <span class="text-dark">{{userInfo.xm}}</span><span>（账号：{{userInfo.yhzh}}）</span>
              </div>
              <div class="workorder-box">
                <div class="mb-2 text-dark">工单等级</div>
                <div class=" flexList">
                  <yzSelect style="width: 40%" :dict="dict.gdzycd" :bound="['applyLevel']" v-model="form"></yzSelect>
                </div>
              </div>
              <div class="workorder-box">
                <div class="mb-2 text-dark">工单类别（问题描述和工单类别请保持一致，我们才能第一时间给你解决）</div>
                <div class=" flexList">
                  <yzSelect style="width: 40%" :dict="dict.gddl" :bound="['sid', 'sortName']" v-model="form"></yzSelect>
                  <yzSelect style="width: 40%; margin-left: 10px" :dict="dict.gdxl" :bound="['tid', 'typeName', 'gh:gh', 'xm:xm', 'dealGh:gh', 'dealXm:xm']" v-model="form" filter="sid"></yzSelect>
                </div>
              </div>
              <div class="workorder-box">
                <div class="msg-required mb-2 text-dark">简述</div>
                <div>
                  <input type="text" name="" id="" value="" class="form-control" placeholder="工单简述" v-model="form.applyTitle"/>
                </div>
              </div>
              <div class="workorder-box">
                <div class="msg-required mb-2">详细描述</div>
                <div>
                  <textarea rows="4" cols="" class="form-control" placeholder="请在此提交您所遇到的相关问题，我们将尽快联系你，谢谢！"  v-model="form.applyContent"></textarea>
                  <el-tag
                      style="margin: 5px"
                      v-for="tag in files"
                      :key="tag.fileUrl"
                      closable
                      @close="closeFile(tag)">
                    <a style="margin: 5px" target="_blank" :title="tag.fileName" :href="getFileUrl(tag.fileUrl)" class="file_item">{{tag.fileName}}</a>
                  </el-tag>
                  <div class="flexList mt-3" style="justify-content: flex-end;">
                    <button type="button" class="btn btn-outline-info mr-3 w-sm h30" @click="$yzUpload().startUpload('UserEducation', uploadRes)">上传附件</button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-info mt-3 h30 w-md" @click="commit">提交工单</button>
          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
